import BDSTheme from "@barracuda-internal/bds-core/dist/styles/themes/baseMui";
import {merge} from "lodash";
import '../theme_fonts/manrope/manrope.css';

const fontFamily = 'Manrope, sans-serif';

const updateFontFamily = (styling: any) => {
    Object.keys(styling).forEach((entry) => {
        if (entry === "fontFamily") {
            styling[entry] = fontFamily;
        } else if (typeof styling[entry] === "object") {
            styling[entry] = updateFontFamily(styling[entry]);
        }
    });
    return styling;
};

/**
 * A material-ui theme, used by the cuda-react/core [CudaReactApp](/?path=/docs/core-components-cudareactapp--cuda-react-app)
 * extended from the [BDSTheme](https://cudaui.com).
 *
 * @name CudaTheme
 */
const CudaTheme = merge({}, updateFontFamily(BDSTheme), {
    typography: {
        body1: {
            fontWeight: 300
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                size: "small"
            }
        },
        MuiTextField: {
            defaultProps: {
                size: "small"
            }
        },
        MuiSelect: {
            defaultProps: {
                size: "small"
            }
        },
        MuiIconButton: {
            defaultProps: {
                size: "small"
            }
        },
        MuiCheckbox: {
            defaultProps: {
                color: "primary"
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: "0.875rem"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                outlined: {
                    marginTop: "2px",
                    marginLeft: "-1px"
                },
                shrink: {
                    fontWeight: "bold"
                }
            }
        },
    },
    palette: {
        // TODO: remove these colors once they are an official part of BDS.
        success: BDSTheme.palette.augmentColor({
            color: {main: "#26D980", light: "#ABF3CF"},
            name: "success"
        }),
        warning: BDSTheme.palette.augmentColor({
            color: {main: "#FF8000", light: "#FFE6CC"},
            name: "warning"
        }),
        error: BDSTheme.palette.augmentColor({
            color: {main: "#FF002B", light: "#FFB8C4"},
            name: "error"
        }),
        info: BDSTheme.palette.augmentColor({
            color: {main: "#8000FF", light: "#E6CCFF"},
            name: "info"
        }),
        neutral: BDSTheme.palette.augmentColor({
            color: {main: "#00AAFF", light: "#A3E0FF", dark: "#0087CD"},
            name: "neutral"
        }),
        inactive: BDSTheme.palette.augmentColor({
            color: {main: "rgb(95, 95, 95)", light: "#5e728c"},
            name: "inactive"
        }),
        risk: {
            low: "#26D980",
            lowMedium: "#99E45E",
            medium: "#FDC913",
            mediumHigh: "#FF8000",
            high: "#FF002B"
        },
        general: {
            yellow: "#FDCA13",
            turquoise: "#24CECE",
            blue: "#00AAFF",
            darkBlue: "#3460FC",
            graphite: "#5E728D",
            purple: "#8000FF",
            magenta: "#E440C0",
            white: "#FFF",
        },
        reporting: {
            titleColor: "#454444",
            fontColor: "#4E4D4D"
        },
        icons: {
            graphics: "#454444",
            lighter: "#52667a",
            pinkBarracudaIcon: "#DCCDE0",
            lightGreenBarracudaIcon: "#C0EFCF",
            blackBarracudaIcon: "#141A1F",
            lightPinkBarracudaIcon: "#EDE5EF"
        }
    }
});

declare module '@mui/material/styles' {
    interface Palette {
        neutral: Palette['primary'];
        inactive: Palette['primary'];
        risk: {
            low: string,
            lowMedium: string,
            medium: string,
            mediumHigh: string,
            high: string
        },
        general: {
            yellow: string,
            turquoise: string,
            blue: string,
            darkBlue: string,
            graphite: string,
            purple: string,
            magenta: string,
            white: string,
        },
        reporting: {
            titleColor: string,
            fontColor: string
        }
        icons: {
            graphics: string,
            lighter: string,
            pinkBarracudaIcon: string,
            lightGreenBarracudaIcon: string,
            blackBarracudaIcon: string,
            lightPinkBarracudaIcon: string
        }
    }

    interface PaletteOptions {
        neutral: PaletteOptions['primary'];
        inactive: PaletteOptions['primary'];
    }
}

export default CudaTheme;