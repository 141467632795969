import en from "./en";
import fr from "./fr";
import ja from "./ja";
import de from "./de";

export default {
    de,
    en,
    fr,
    ja
};